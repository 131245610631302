import {Box, CircularProgress, Sheet, Stack} from '@mui/joy';
import React, {type CSSProperties, useEffect, useRef, useState} from 'react';

import {
	useFetchScanData,
	useGumballInteractions,
	useLoadAnnotations,
	useLoadDigitalTwins,
	useLoadLandmarks,
	useLoadResectionPlanes,
	useLoadMasks,
	useLoadMeshes,
	useLoadPointClouds,
	useViewportLoadingProgress,
} from '@/hooks';
import {isDefined} from '@/library';
import {
	elements as cornerstoneElements,
	viewportElementKeys,
	type ViewportElementKey,
} from '@/library/cornerstone';
import {type Scan} from '@/library/models';
import {useGlobalState} from '@/state';

import {
	ScreenshotButton,
	SidePanel,
	SnapCameraDropdown,
	SurgicalPlanOverlay,
	ViewportLoadingSpinner,
} from './components';
import {
	useInitializeViewport,
	useInitializeVtk,
	useSetCustomDefaults,
} from './hooks';

let onComplete = () => {
	/* Do nothing */
};

function Viewer({scan}: {readonly scan: Scan}) {
	const {
		densityMap,
		digitalTwins,
		meshes,
		resectionPlanes: resectionPlanesState,
		visibility,
		viewports: {volumeViewport},
	} = useGlobalState();
	const [are2dViewportsLoaded, setAre2dViewportsLoaded] = useState(false);
	const [isVolumeViewportLoaded, setIsVolumeViewportLoaded] = useState(false);

	const {error: fetchScanDataError} = useFetchScanData({scan});

	const volumeViewportRef = useRef<HTMLDivElement>(null);

	const surgicalPlanRef = useRef<HTMLDivElement>(null);

	const {dicomImageLoadingProgress, volumeId} = useInitializeViewport({
		scan,
	});

	useInitializeVtk(volumeViewportRef);
	useGumballInteractions(volumeViewportRef);

	useSetCustomDefaults({scan});

	const {isLoaded: areMasksLoaded} = useLoadMasks({
		scan,
		volumeId,
	});

	useLoadMeshes({
		scan,
	});

	useLoadPointClouds({
		scan,
	});

	useLoadResectionPlanes({
		arePointCloudsLoaded: densityMap.state === 'ready',
		scan,
	});

	const [maximizedViewport, setMaximizedViewport] = useState<
		ViewportElementKey | undefined
	>();

	const {isLoaded: areLandmarksLoaded} = useLoadLandmarks({
		arePointCloudsLoaded: densityMap.state === 'ready',
		scan,
	});

	useLoadAnnotations({
		scan,
	});

	useLoadDigitalTwins({
		arePointCloudsLoaded: densityMap.state === 'ready',
		scan,
	});

	const viewportLoadingProgress = useViewportLoadingProgress({
		areLandmarksLoaded,
		areMasksLoaded,
		densityMapState: densityMap.state,
		dicomImageLoadingProgress,
		digitalTwinsState: digitalTwins.state,
		resectionPlanesState: resectionPlanesState.state,
		scan,
	});

	function handleViewportDoubleClick(viewport: ViewportElementKey) {
		const isViewportAlreadyMaximized = viewport === maximizedViewport;

		setMaximizedViewport(isViewportAlreadyMaximized ? undefined : viewport);
		if (viewport === 'volume') {
			volumeViewport.setIsMaximized(!volumeViewport.isMaximized);
		}
	}

	function handleViewportClick({
		event,
		viewport,
	}: {
		event: React.MouseEvent;
		viewport: ViewportElementKey;
	}) {
		const isDoubleClick = event.detail === 2;

		if (isDoubleClick) handleViewportDoubleClick(viewport);
	}

	useEffect(() => {
		if (volumeViewportRef.current) {
			cornerstoneElements.volume = volumeViewportRef.current;
		}
	}, []);

	return (
		<Stack
			direction="row"
			sx={{
				backgroundColor: 'primary.700',
				height: '100%',
				padding: 0.5,
				width: '100%',
			}}
		>
			{/* Container for the viewport grid */}
			<Box
				sx={{
					flexGrow: 1,
					paddingRight: 0.25,
					position: 'relative',
					height: '100%',
				}}
			>
				{visibility.surgicalPlan && isDefined(scan.resectionPlanes) && (
					<SurgicalPlanOverlay
						componentRef={surgicalPlanRef}
						resectionPlanes={scan.resectionPlanes}
					/>
				)}

				{/* Viewport grid */}
				<Box
					style={{
						display: 'grid',
						gap: 4,
						gridTemplateColumns: 'repeat(2, 1fr)',
						height: '100%',
					}}
				>
					{viewportElementKeys.map((key) => {
						const isThisViewportMaximized = maximizedViewport === key;
						const isOtherViewportMaximized =
							maximizedViewport && !isThisViewportMaximized;
						const isVolumeViewport = key === 'volume';
						const isVolumeViewportUpdating =
							isVolumeViewport &&
							((scan.hasReachedMilestone('kneeSegmented') &&
								densityMap.state === 'updating') ||
								(scan.hasReachedMilestone('landmarked') &&
									(resectionPlanesState.state === 'updating' ||
										digitalTwins.state === 'updating')));

						let cursor: CSSProperties['cursor'];
						let activeCursor: CSSProperties['cursor'];
						let isLoading = false;
						let loadingProgress = 0;

						if (isVolumeViewport) {
							cursor = 'grab';
							activeCursor = 'grabbing';

							if (scan.hasReachedMilestone('kneeSegmented')) {
								loadingProgress = viewportLoadingProgress.volumeViewport;
								onComplete = () => {
									setIsVolumeViewportLoaded(true);
								};

								isLoading = !isVolumeViewportLoaded;
							}
						} else {
							loadingProgress = viewportLoadingProgress.twoDimensionalViewport;
							onComplete = () => {
								setAre2dViewportsLoaded(true);
							};

							isLoading = !are2dViewportsLoaded;
						}

						return (
							<Box
								key={key}
								ref={
									isVolumeViewport
										? volumeViewportRef
										: (ref: HTMLDivElement) => {
												cornerstoneElements[key] = ref;
										  }
								}
								data-testid={`viewport:${key}`}
								sx={{
									backgroundColor: 'neutral.900',
									borderRadius: 'sm',
									// `!important` is necessary because Cornerstone sets the
									// cursor via the `style` attribute
									cursor:
										cursor === undefined ? undefined : `${cursor} !important`,
									display: isOtherViewportMaximized ? 'none' : undefined,
									gridColumn: `span ${isThisViewportMaximized ? 2 : 1}`,
									gridRow: `span ${isThisViewportMaximized ? 2 : 1}`,
									overflow: 'hidden',
									position: 'relative',
									'&:active': {
										cursor:
											activeCursor === undefined
												? undefined
												: `${activeCursor} !important`,
									},
								}}
								onClick={(event) => {
									handleViewportClick({event, viewport: key});
								}}
								onContextMenu={(event) => {
									event.preventDefault();
								}}
							>
								<Box
									id={`viewport:${key}`}
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: 1,
										left: 8,
										position: 'absolute',
										top: 8,
										zIndex: 1,
									}}
								>
									<ScreenshotButton viewportKey={key} />
									{isVolumeViewport && <SnapCameraDropdown />}
								</Box>
								{(isLoading || isVolumeViewportUpdating) && (
									<ViewportLoadingSpinner
										targetProgress={
											isVolumeViewportUpdating ? undefined : loadingProgress
										}
										onComplete={onComplete}
									/>
								)}
							</Box>
						);
					})}
				</Box>
			</Box>

			{/* Side panel */}
			<Box
				sx={{
					paddingLeft: 0.25,
					width: '400px',
				}}
			>
				<Sheet
					sx={{
						borderRadius: 'sm',
						height: '100%',
						overflow: 'auto',
						padding: 2,
						position: 'relative',
					}}
				>
					{cornerstoneElements.volume === undefined ? (
						<Box
							sx={{
								alignItems: 'center',
								display: 'flex',
								height: '100%',
								left: 0,
								justifyContent: 'center',
								position: 'absolute',
								top: 0,
								width: '100%',
							}}
						>
							<CircularProgress />
						</Box>
					) : (
						<SidePanel
							scan={scan}
							surgicalPlanRef={surgicalPlanRef}
							unrecoverableError={fetchScanDataError ?? meshes.error}
						/>
					)}
				</Sheet>
			</Box>
		</Stack>
	);
}

export default Viewer;
